import {EmployeeType} from "../dto/employee.type.dto";
export class CommonFunctions  {
    public static getEmployeeType(inputType:string):EmployeeType{
        let returntype: EmployeeType ;
        switch(inputType){
            case EmployeeType.Nephrologists:
            returntype =EmployeeType.Nephrologists
            break;
            case EmployeeType.NursePractitioners:
            returntype =EmployeeType.NursePractitioners
            break;                 
        }
        return returntype;
    }

    public static groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    }  

    public static scrolltoTop() {
        let scrollToTop = window.setInterval(() => {
          let pos = window.pageYOffset;
          if (pos > 0) {
            window.scrollTo(0, pos - 50); // how far to scroll on each step
          } else {
            window.clearInterval(scrollToTop);
          }
        }, 16);
    }

    public static isObjectEmpty(Obj) {
      for(var key in Obj) {
      if(Obj.hasOwnProperty(key))
      return false;
      }
      return true;
      }
  }
  