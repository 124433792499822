import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'help-contact',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit{
    constructor( private route: ActivatedRoute,
      private router: Router){
     
    }
    ngOnInit() {
        
    } 
    goToContact(){
      this.router.navigate(['/contact']);
    }   
}
