import { Component, OnInit } from '@angular/core';
import { IAboutUsDTO } from '../../dto/about.us.dto';
import { IProvidersDTO } from '../../dto/providers.dto';
import { IHomeInfoViewModel } from '../../viewmodels/homeinfo.viewmodel'; 

@Component({
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit {
  constructor(){

  }

    ngOnInit():void
    {
    }
    patientsMain={
        heading:"PATIENTS",
        message:"Our passion is to put your health first."
    }
      
    patientsInfo ={
        heading:`Our team at Cenla Kidney Specialists would like to thank you for choosing 
                 us as your trusted health care provider.`,
        information:`Use the following forms. Feel free to call us to 
                      prepare and manage your personal health records..`,
    }
    diseaseInfo ={
      heading:"DISEASE INFO",
      imageUrl:"",
      information:`Interested in learning about Kidney Disease, End Stage Kidney Disease, Acute Kidney Injury, 
                   Kidney Stones and Kidney Transplant? Go to the website of "American Society of Nephrology" by clicking the button below.`,
      route:'https://www.asn-online.org/kidneydisease/info/'
    }
    preventionInfo ={
      heading:"PREVENTION",
      imageUrl:"",
      information:`Interested in learning about Prevention of Kidney Disease, simple ways to check Kidney Disease and major risk factor? Go to the website of "National Kidney Foundation" 
                   by clicking the button below.`,
      route:'https://www.kidney.org/prevention'
    }
    
    externalLinkNavigate(path){
      window.open(path,"_blank");
    }

    downloadPatientForm(){
      let link= document.createElement("a");
      link.download ="patientCKS";
      link.href = "../assets/forms/patient_form.pdf"
      link.click();
    }

    pateintForms =[
      {
        formDownloadName:"New Patient CKS",
        path:"../assets/forms/new_patient_form.pdf",
        formDisplayName:"new patient form"
      },
      {
        formDownloadName:"Patient Consent CKS",
        path:"../assets/forms/consent_form.pdf",
        formDisplayName:"patient consent form"
      }
    ]
    downloadForm(fullPath:string,formName:string){
      let link= document.createElement("a");
      link.download =formName;
      link.href = fullPath;
      link.click();
    }
    

}
