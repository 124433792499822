import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.css']
})
export class ReferralsComponent implements OnInit {
  constructor(){

  }

  ngOnInit():void
  {
  }
  referrals={
    heading:"REFERRALS",
    message:"We make it easy to refer a patient.",
    contact:"(318) 704-0001"
  }

  downloadReferralForm(){
    let link= document.createElement("a");
    link.download ="referralCKS";
    link.href = "../assets/forms/patient_form.pdf"
    link.click();
  }

  referralFormInfo ={
    formDownloadName:"Referral CKS",
    path:"../assets/forms/patient_referral_form.pdf",
    formDisplayName:"patient referral form"
  }
  downloadForm(fullPath:string,formName:string){
    let link= document.createElement("a");
    link.download =formName;
    link.href = fullPath;
    link.click();
  }
}
