/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/button/button";
import * as i3 from "./help.component";
import * as i4 from "@angular/router";
var styles_HelpComponent = [i0.styles];
var RenderType_HelpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HelpComponent, data: {} });
export { RenderType_HelpComponent as RenderType_HelpComponent };
export function View_HelpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-xs-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-question-circle fa-5x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-xs-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [["class", "text-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["QUESTIONS"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "col-sm-offset-0 col-md-offset-3 col-md-6 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We are here to help. Your health is our priority. Our Kidney Specialist will help you get your questions answered. "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "col-xs-12 text-center text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" For medical emergency, please dial 911. "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "col-xs-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "ui-button-secondary gray-border"], ["icon", "fa fa-phone"], ["iconPos", "right"], ["label", "Contact Us"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToContact() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 4341760, null, 0, i2.ButtonDirective, [i1.ElementRef], { iconPos: [0, "iconPos"], label: [1, "label"], icon: [2, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "right"; var currVal_1 = "Contact Us"; var currVal_2 = "fa fa-phone"; _ck(_v, 15, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_HelpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "help-contact", [], null, null, null, View_HelpComponent_0, RenderType_HelpComponent)), i1.ɵdid(1, 114688, null, 0, i3.HelpComponent, [i4.ActivatedRoute, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpComponentNgFactory = i1.ɵccf("help-contact", i3.HelpComponent, View_HelpComponent_Host_0, {}, {}, []);
export { HelpComponentNgFactory as HelpComponentNgFactory };
