<div>
    <div class="panel panel-primary"> 
        <div class="panel-body">
                <section class="header" style="background-image:url(../assets/images/backgrounds/home.jpg)">
                    <div class="content-home">
                        <h2><strong>{{homeMain.heading}}</strong></h2>
                        <div class="home-message" >                    
                                    {{homeMain.message}}                            
                        </div>                                               
                    </div>    
                </section>
        </div>           
        <div class="panel-body">
            <div class="margin-top-10">               
                <div class="row" style="padding-left:15px; padding-right: 15px;" *ngIf="config !== undefined && config.hasAlert ==='True'">
                        <div class="alert alert-danger alert-font-size text-center" role="alert">{{config.alertMessage}}</div>
                </div>
                <div class="container-fluid bg-1 text-center">  
                <h3>About Us</h3>  
                <div class="row">
                            <div class="col-md-4">
                                <img [src]="homeMain.aboutUsImageUrl1" class="img img-responsive margin img-circle show-pointer" alt="Padam" (click)="navigate('aboutus')" style="display:block;margin:auto;"/> 
                            </div>
                            <div class="col-md-4">
                                <img [src]="homeMain.aboutUsImageUrl2" class="img img-responsive margin img-circle show-pointer" alt="Soudan" (click)="navigate('aboutus')" style="display:block;margin:auto;"/>   
                            </div>
                            <div class="col-md-4">
                                <img [src]="homeMain.aboutUsImageUrl3" class="img img-responsive margin img-circle show-pointer" alt="wilson" (click)="navigate('aboutus')" style="display:block;margin:auto;"/>   
                            </div>
                </div>                                                    
                    <br/>
                    <p class="font-size-17">
                        {{homeMain.aboutUsInfo}}
                    </p>             
                </div>                
            </div>
        </div> 
        <hr/>
        <div class="panel-body">
                <div class="row">                    
                    <div class="col-md-6" style="margin-top: 5px;">
                        <p-card header={{patientsInfo.heading}}  [style]="{width: '100%'}" styleClass="ui-card-shadow">
                            <p-header>
                                <img  [src]="patientsInfo.imageUrl"  class="img img-responsive show-pointer" alt="Patients" (click)="navigate('patients')">
                            </p-header>
                            <div class="font-size-17">
                                {{patientsInfo.information}}
                            </div>
                            <!--
                                <p-footer>
                                    <button pButton type="button" label="LEARN MORE" icon="pi pi-check" style="margin-right: .25em" (click)=" goToPatients();"></button>                                  
                                </p-footer>
                            -->                         
                        </p-card>
                    </div>
                    <div class="col-md-6" style="margin-top: 5px;">
                        <p-card header={{referralsInfo.heading}}  [style]="{width: '100%'}" styleClass="ui-card-shadow">
                            <p-header>
                                <img  [src]="referralsInfo.imageUrl"  class="img img-responsive show-pointer" alt="Referarals" (click)="navigate('referrals')">
                            </p-header>
                            <div class="font-size-17">
                                {{referralsInfo.information}}
                            </div>
                            <!--
                                <p-footer>
                                    <button pButton type="button" label="LEARN MORE" icon="pi pi-check" style="margin-right: .25em" (click)="goToReferrals();"></button>                                  
                                </p-footer>
                            -->                           
                        </p-card>
                    </div>
                </div>
                <!--
                    <div class="row">                    
                        <div class="col-md-6" style="margin-top: 5px;">
                            <p-card header={{educationInfo.heading}}  [style]="{width: '100%'}" styleClass="ui-card-shadow">
                                <p-header>
                                    <img  [src]="educationInfo.imageUrl"  class="img img-responsive" alt="Patients">
                                </p-header>
                                <div>
                                    {{educationInfo.information}}
                                </div>
                            
                                <p-footer>
                                    <button pButton type="button" label="LEARN MORE" icon="pi pi-check" style="margin-right: .25em"></button>                                  
                                </p-footer>
                        
                            </p-card>
                        </div>
                        <div class="col-md-6" style="margin-top: 5px;">
                            <p-card header={{treatmentInfo.heading}}  [style]="{width: '100%'}" styleClass="ui-card-shadow">
                                <p-header>
                                    <img  [src]="treatmentInfo.imageUrl"  class="img img-responsive" alt="Patients">
                                </p-header>
                                <div>
                                    {{treatmentInfo.information}}
                                </div>
                            
                                <p-footer>
                                    <button pButton type="button" label="LEARN MORE" icon="pi pi-check" style="margin-right: .25em"></button>                                  
                                </p-footer>
                                                    
                            </p-card>
                        </div>
                    </div>
                -->    
        </div> 
        <div  class="panel-body">
            <br/>
            <br/>
            <br/>
            <help-contact></help-contact>
        </div>      
    </div>
</div>