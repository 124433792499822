 <nav class="navbar navbar-custom navbar-fixed-top">
    <div class="container-fluid">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-header"> 
        <!--
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false" aria-controls="bs-example-navbar-collapse-1">
        -->
        <button type="button" class="navbar-toggle collapsed"  (click)="toggleNavBar()" aria-expanded="false" aria-controls="bs-example-navbar-collapse-1">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button> 
        <div  (click)="toggleIfActive()">
            <a class="navbar-brand" routerLinkActive="active" routerLink="home"> 
                <img [src]="logoImageUrl" alt="CKS" class="logo"> 
            </a>
        </div>         
        <h4 class="brand-text"><b>CENLA KIDNEY SPECIALISTS LLC</b></h4>
      </div>
      <div class="collapse navbar-collapse" [ngClass]="{'in': shownavbar}" id="bs-example-navbar-collapse-1">
        <ul class="nav navbar-nav">
          <li routerLinkActive="active"  (click)="toggleNavBar()">  
                <a routerLink="aboutus" class="font-size-18">
                    <i class="fa fa-info-circle" aria-hidden="true" ></i>  About Us
                </a>                          
          </li>
          <li routerLinkActive="active" (click)="toggleNavBar()">
              <a  routerLink="patients" class="font-size-18">
                  <i class="fa fa-hospital-o" aria-hidden="true"></i> Patients
              </a>
          </li>
          <li routerLinkActive="active" (click)="toggleNavBar()">
              <a  routerLink="referrals" class="font-size-18">
                  <i class="fa fa-hand-o-right" aria-hidden="true"></i> Referrals
              </a>
          </li> 
          <li  routerLinkActive="active" (click)="toggleNavBar()">
            <a  routerLink="research" class="font-size-18">
              <i class="fa fa-flask" aria-hidden="true"></i> Research
            </a>
        </li>           
        </ul>
        <ul class="nav navbar-nav navbar-right">
          <li routerLinkActive="active" (click)="toggleNavBar()">
              <a  routerLink="contact" class="font-size-18 other-navigation-font">
                  <i class="fa fa-volume-control-phone" aria-hidden="true"></i> Contact Us
              </a>
          </li>
        </ul>
      </div><!-- /.navbar-collapse -->
    </div><!-- /.container-fluid -->
  </nav>
  <div class="container margin-top-95">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
  
  <div class="alert alert-info footer-desing">
    <div class="row">
          <div class="col-xs-12 text-center">
              LOCATIONS:
            </div>    
            <div class="col-md-offset-3 col-md-3">
                <div class="col-xs-12 text-center text-bold">
                    CENLA KIDNEY SPECIALISTS
                 </div>
                <div class="col-xs-12">
                    <address class="text-center">                      
                        <div>
                                2220 Worley Drive
                        </div>
                        <div>
                            Alexandria, Louisiana 71301
                        </div>
                    </address>  
                </div>
                 <div class="col-xs-12 text-center">
                    P (318) 704-0001
                 </div>
                 <div class="col-xs-12 text-center">
                    F (318) 704-0002
                 </div>
            </div>  
            <div class="col-md-3">
              <div class="col-xs-12 text-center text-bold">
                  CENLA KIDNEY SPECIALISTS
               </div>
              <div class="col-xs-12">
                  <address class="text-center">                      
                      <div>
                        151 Jefferson Davis Blvd, Suite A
                      </div>
                      <div>
                        Natchez, Mississippi 39120
                      </div>
                  </address>  
              </div>
               <div class="col-xs-12 text-center">
                  P (601) 273-3554
               </div>
               <div class="col-xs-12 text-center">
                  F (601) 653-9589
               </div>
          </div>          
      <div class="col-md-12">
             <div class="text-center">
                    <i class="fa fa-facebook-official fa-2x cursor-pointer" aria-hidden="true"></i>
             </div>
      </div>   
    </div>
  </div>

