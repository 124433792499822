import { NgModule } from '@angular/core';
import { FieldsetModule } from 'primeng/fieldset';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { GMapModule } from 'primeng/gmap';
import { GalleriaModule } from 'primeng/galleria';
import {CardModule} from 'primeng/card';
import {PanelModule} from 'primeng/panel';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DropdownModule} from 'primeng/dropdown';

@NgModule({
  imports: [FieldsetModule,
            ButtonModule, 
            DialogModule,
            GMapModule,
            GalleriaModule,
            CardModule,
            PanelModule,
            ProgressSpinnerModule,
            DropdownModule
          ],
  exports: [FieldsetModule, 
            ButtonModule,
            DialogModule,
            GMapModule,
            GalleriaModule,
            CardModule,
            PanelModule,
            ProgressSpinnerModule,
            DropdownModule]
})
export class PrimeNgImportsModule { }
