<div>
    <div class="row">
        <div class="col-xs-12 text-center">
               <i class="fa fa-question-circle fa-5x" aria-hidden="true"></i>
        </div>
        <div class="col-xs-12 text-center">
              <h4 class="text-info">QUESTIONS</h4>
        </div>
        <div class="col-sm-offset-0 col-md-offset-3 col-md-6 text-center">
               We are here to help. 
               Your health is our priority. 
               Our Kidney Specialist will help you get your questions answered.
        </div>
        <div class="col-xs-12 text-center text-danger">
               For medical emergency, please dial 911.
        </div>
   </div> 
   <br/>
   <div class="row">
       <div class="col-xs-12 text-center">
               <button pButton type="button" icon="fa fa-phone" iconPos="right" label="Contact Us"  class="ui-button-secondary gray-border" (click)="goToContact();" >                  
               </button>
       </div>
   </div>
</div>