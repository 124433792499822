import { Component,PLATFORM_ID, APP_ID, Inject, OnInit} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {CommonFunctions} from "../app/sharedFunctions/commom.functons"
import { $ } from 'protractor';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'project';
  logoImageUrl:string ="assets/images/logo/logo.PNG";
    constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
    ) {

    }
    shownavbar:boolean= false;
    ngOnInit(){
     
    }
    preventFormPost(e){
     // e.preventDefault();
    }
    toggleNavBar(){
      this.shownavbar =!this.shownavbar;
    }
    toggleIfActive(){
      if(this.shownavbar){
        this.shownavbar =!this.shownavbar;
      }
    }
    onActivate(event: any) {
      if (isPlatformBrowser(this.platformId)) {
          CommonFunctions.scrolltoTop();
      }
    }

    navigteToFacebook(){
      window.open("","_blank")
    }
}
