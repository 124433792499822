import { Component , OnInit} from '@angular/core';
import {FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { fillProperties } from '@angular/core/src/util/property';
import {ConfigService} from "../../services/config.service";
import {IConfigDTO} from "../../dto/config.message.dto";
import { Observable } from 'rxjs';

@Component({
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent  implements OnInit {
  title = 'Contact';
  config:IConfigDTO;
   //Form Builder Class is provided as a Service
   constructor(private _configService:ConfigService)
   {
 
   }

  ngOnInit()
  {
  this.showConfig();
  }
  showConfig(){
    this._configService.getConfig()
    .subscribe((data: IConfigDTO) =>this.config = {...data});
  }
  contactUsInfo ={
    header:"Contact Us!",
    message:`We are really excited to hear from you. If you have any 
             questions, contact us using the contact information mentioned below.`,
    mapImageUrl:"assets/images/contact/map.jpg"
  }
  
}
