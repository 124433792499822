<div>
    <div class="jumbotron">
        <h1 class="text-center text-primary">{{contactUsInfo.header}}</h1>
        <p class="text-center">
                {{contactUsInfo.message}}         
        </p>
    </div>       
    <div class="row" style="padding-left:15px; padding-right: 15px;" *ngIf="config !== undefined && config.hasAlert ==='True'">
            <div class="alert alert-danger alert-font-size text-center" role="alert">{{config.alertMessage}}</div>
    </div>
    <div class="row" style="margin-bottom: 30px;">  
        <div class="col-md-6">
            <div class="text-center">                                   
                <h3>
                    CENLA KIDNEY SPECIALISTS
                </h3>  
            <div>
                <address>                      
                    <div class="font-size-17">
                            2220 Worley Drive
                    </div>
                    <div class="font-size-17">
                        Alexandria, Louisiana 71301
                    </div>
                </address>  
            </div>                                                  
            <div class="font-size-17"> 
                <i class="fa fa-phone-square" aria-hidden="true"></i> P (318) 704-0001 
            </div>  
            <div class="font-size-17">
                <i class="fa fa-fax" aria-hidden="true"></i>  F (318) 704-0002 
            </div> 
            <div class="font-size-17">
                <i class="fa fa-envelope" aria-hidden="true"></i> E <a href="mailto:cenlakidneyspecialists@gmail.com">cenlakidneyspecialists@gmail.com</a>
            </div>                  
        </div>          
        </div> 
        <div class="col-md-6">
            <div class="text-center">                                   
                <h3>
                    CENLA KIDNEY SPECIALISTS
                </h3>  
            <div>
                <address>                      
                    <div class="font-size-17">
                        151 Jefferson Davis Blvd, Suite A
                    </div>
                    <div class="font-size-17">
                        Natchez, Mississippi 39120
                    </div>
                </address>  
            </div>                                                  
            <div class="font-size-17"> 
                <i class="fa fa-phone-square" aria-hidden="true"></i> P (601) 273-3554
            </div>  
            <div class="font-size-17">
                <i class="fa fa-fax" aria-hidden="true"></i>  F (601) 653-9589 
            </div> 
            <div class="font-size-17">
                <i class="fa fa-envelope" aria-hidden="true"></i> E <a href="mailto:cenlakidneyspecialists@gmail.com">cenlakidneyspecialists@gmail.com</a>
            </div>                  
        </div>          
        </div>     
                
    </div> 
    <div class="row" style="margin-bottom: 30px;">        
        <div class="text-center">                                   
            <h3>
                OFFICE HOURS: 
            </h3>                                                        
            <div class="font-size-17"> 
               Monday - Friday : 8:30AM - 5:00PM             
            </div> 
            <div class="font-size-17 text-danger"> 
                Hours may differ in public holidays          
            </div>                    
        </div>           
    </div>
    <div class="col" style="padding-bottom: 10px;">
            <img [src]="contactUsInfo.mapImageUrl" class="img-responsive" alt="No Image" style="margin:auto;"/>
    </div>
</div>


