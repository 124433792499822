<div>
    <div class="panel panel-primary"> 
        <div class="panel-body">
                <section class="header" style="background-image:url(../assets/images/backgrounds/patient.jpg);text-align: right;">
                    <div class="content-patient">
                        <!--
                               <h3>{{patientsMain.heading}}</h3>
                        -->
                        
                        <div class="row">
                                <div class="col-md-offset-6 col-md-6">
                                        <h1 class="text-center">
                                                {{patientsMain.message}}
                                        </h1>  
                                </div>
                        </div>
                                                                       
                    </div>    
                </section>
        </div>     
        <div class="panel-body">
            <div class="container-fluid bg-3 text-center">
                    <h2 class="col-sm-offset-0 col-md-offset-1 col-md-10 text-center">
                            {{patientsInfo.heading}}
                    </h2>
                    <p class="col-sm-offset-0 col-md-offset-1 col-md-10  text-center font-size-17">
                        {{patientsInfo.information}}
                    </p>
            </div>
           
            <div class="container-fluid bg-3 text-center">
                 <div class="row">
                     <div class="col-md-6 margin-bottom-5" *ngFor="let form of pateintForms">
                            <div class="col">
                                    <i class="fa fa-file-pdf-o fa-5x cursor-pointer" aria-hidden="true" (click)="downloadForm(form.path, form.formDownloadName)"></i>
                             </div>
                             <div class="col">
                                    Download {{form.formDisplayName}}
                             </div>
                    </div>                   
                 </div>
           </div>      
            <div class="container-fluid bg-2 text-center">
                    <h3>{{diseaseInfo.heading}}</h3>
                    <p class="font-size-17">{{diseaseInfo.information}} </p>
                    <div class="col">
                        <button pButton type="button" label="Learn About Kidney Disease....." class="ui-button-secondary" (click)="externalLinkNavigate(diseaseInfo.route);"></button>
                    </div>
            </div>                       
            <div class="container-fluid bg-1 text-center">
                    <h3>{{preventionInfo.heading}}</h3>
                    <p class="font-size-17">{{preventionInfo.information}} </p>
                    <div class="col">
                        <button pButton type="button" label="Learn About Prevention....." class="ui-button-secondary" (click)="externalLinkNavigate(preventionInfo.route);"></button>
                    </div>
            </div>      
        </div>                
        <hr/>       
        <div  class="panel-body">
            <br/>
            <br/>
            <br/>
            <help-contact></help-contact>
        </div>      
    </div>
</div>