import { Injectable } from '@angular/core';
import { IEmployeeInfoDTO} from '../dto/employee.info.dto';
import {EmployeeType} from "../dto/employee.type.dto";
import { IEmployeeInfoSallowDTO} from '../dto/employee.info.sallow.dto';
import {IEmployeeNameId} from "../dto/employee.name.id.dto";
import {IEmployeeGropTypeNameIdDTO} from "../dto/employee.groupType.name.id";
import {HttpClient} from "@angular/common/http"
import { Observable, of} from 'rxjs';
import {first, find, map,reduce} from "rxjs/operators"
@Injectable()
export class EmployeeInfoService {
    employeeURL="assets/data/db/employees.json"
    constructor(private _http:HttpClient){

    }

     getSingleEmployee(id:number): Observable<IEmployeeInfoDTO>{     
          if(!isNaN(id)){
            let idCompare = Number(id);
            return this._http.get<IEmployeeInfoDTO[]>(this.employeeURL).pipe(map(x=>{
                let emp = x.find(x=>Number(x.id) === idCompare)
                if(emp!== null && emp!== undefined){
                    return emp;
                }
                return null;
            }));
        }    
        return of<IEmployeeInfoDTO>(null);     
     }
    
    getAllEmployeesSallow(): Observable<IEmployeeInfoSallowDTO[]>{
        return this._http.get<IEmployeeInfoSallowDTO[]>(this.employeeURL).pipe
                (map(x=>{
                    return x.map(x=>({
                        id:x.id,
                        fullName:x.fullName,
                        imageUrl:x.imageUrl,
                        title:x.title,
                        type:x.type
                }))
            }));    
    }
     
}