import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.css']
})
export class ResearchComponent implements OnInit {
  constructor(){

  }

  ngOnInit():void
  {
  }
  researchPage= {
    header:"RESEARCH",
    message:"Learn about our research"
  }
  researchInfo = {
    messageHeader:`Clinical research is an integral part of modern medicine.
     Our providers have tremendous experience in clinical 
     research and are working to bring clinical research 
     into the Central Louisiana area.`,
     messageBody:`Dr. Hirachan had participated in several 
     phase 3 clinical trials and will be conducting clinical 
     trial soon. The following are various clinical trials 
     where Dr. Hirachan has participated as sub investigator:`,
     clinicalTrials :[
       `A Phase 3, Multi center, Randomized, Double-Blind, Placebo-Controlled 
       Study Evaluating the Safety and Efficacy of Roxadustat for the Treatment 
       of Anemia in Chronic Kidney Disease Patients not on Dialysis.`,
       `A Phase 3 Randomized, Double Blind, Placebo-Controlled , Multi Center Study to Evaluate the Safety and Efficacy
       of Pyridoxin (pyridoxamine dihydrochloride) in Subjects with Nephropathy Due to Type 2 Diabetes (PIONEER).`,
       `An open-label extension to study ZS-004, a Phase 3 Multi center, Multi-phase, Multi Dose, Prospective, Randomized, Double- blind, Placebo-controlled Maintenance Study to Investigate the Safety and Efficacy of ZS (Microporous,
        Fractionated, Protonated Zirconium Silicate), an Oral Sorbent, in Subjects with Hyperkalemia.`,
        `Uremic toxin removal, blood cell kinetics, and inflammatory response in patients treated with the Allient System.`,
        `Monitoring body hydration state in hemodialysis patients with POD compared to Hydra 4200, ZOE and DF50
        bioimpedance devices.`,
        `Doppler Ultrasound for detecting Impending failure of Dialysis Access Grafts.`,
        ` An open -label, multi-center study to document the efficacy, safety and tolerability of long-term administration of
        RO0503821 in patients with chronic renal anemia.`,
        `Outcome trial evaluating the efficacy and safety of Norditropin in adult patients on chronic Hemodialysis.`
     ],
     messageFooter:"Please stay tuned for further research opportunities."
  }

}