import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { IAboutUsDTO } from '../../dto/about.us.dto';
import { IProvidersDTO } from '../../dto/providers.dto';
import { IHomeInfoViewModel } from '../../viewmodels/homeinfo.viewmodel'; 
import {ConfigService} from "../../services/config.service";
import {IConfigDTO} from "../../dto/config.message.dto";
import { Observable } from 'rxjs';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  config:IConfigDTO;
      constructor( private route: ActivatedRoute,
        private router: Router,
        private _configService:ConfigService){
        
      }

  ngOnInit() {
    this.showConfig();
  }

  showConfig(){
    this._configService.getConfig()
    .subscribe((data: IConfigDTO) =>this.config = {...data});
  }

  homeMain={
    heading:"CENLA KIDNEY SPECIALISTS",
    message:"Working for your healthier life.",
    aboutUsImageUrl1:"assets/images/aboutus/padam.jpg",
    aboutUsImageUrl2:"assets/images/aboutus/soudan.jpg",
    aboutUsImageUrl3:"assets/images/aboutus/wilson.jpg",
    aboutUsInfo:`Our mission is to provide quality care to our patients.
                  We put our patients' health first. Click our physican's image 
                  above to learn more about us.`
  }

  patientsInfo:IHomeInfoViewModel ={
    heading:"PATIENTS",
    imageUrl:"assets/images/home/patients.jpg",
    information:`Are you a new or a returning patient?
                  We are comitted to provide an exceptional
                  experience to our patients.
                  Click the image above for more patient info.....`,
    route:""
  }
  referralsInfo:IHomeInfoViewModel ={
    heading:"REFERRALS",
    imageUrl:"assets/images/home/referral.jpg",
    information:`We are excited to be an effective partner with
                  any Referring Physican. We value the patient care.
                  Click the image above to learn how to send us a patient info.....`,
    route:""
  }

  diseaseInfo:IHomeInfoViewModel ={
    heading:"DISEASE INFO",
    imageUrl:"",
    information:`Interested in learning about Kidney Disease, End Stage Kidney Disease, Acute Kidney Injury, 
                  Kidney Stones and Kidney Transplant? Go to the website of "American Society of Nephrology" by clicking the button below.`,
    route:'https://www.asn-online.org/kidneydisease/info/'
  }
  preventionInfo:IHomeInfoViewModel ={
    heading:"PREVENTION",
    imageUrl:"",
    information:`Interested in learning about Prevention of Kidney Disease, simple ways to check Kidney Disease and major risk factor? Go to the website of "National Kidney Foundation" 
                  by clicking the button below.`,
    route:'https://www.kidney.org/prevention'
  }


  navigate(path:string) {
    
      this.router.navigate([`/${path}`]); 
  
  }
}
