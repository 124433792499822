<div>  
    <div class="panel panel-primary">
            <div class="panel-body">
                <section class="header" style="background-image:url(../assets/images/backgrounds/aboutus.jpg)">
                    <div class="content-aboutus">
                            <h1>ABOUT US</h1>
                            <h3>
                                Want to know more about our healthcare providers?
                                                        
                            </h3> 
                            <h3>Learn about them and their expertise.</h3>                                                
                    </div>    
                </section>
            </div>
            <div class="panel-body">
                <h2 class="col-sm-offset-0 col-md-offset-1 col-md-10 text-center">{{aboutUs.heading}}</h2>
                <p class="col-sm-offset-0 col-md-offset-1 col-md-10  text-center font-size-17">
                    {{aboutUs.message}}
                </p>
            </div>
            <div class="specialization-message text-center">
                <div class="media margin-top-10">
                    <div class="media-body">
                        <h2 class="media-heading  text-primary">{{ourSpecilization.heading}}</h2>
                        <div *ngFor="let message of ourSpecilization.messages;" class="font-size-17">
                            {{message}}
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-6" *ngFor="let provider of providersInfo;">
                        <p-fieldset legend={{provider.fullName}}>
                            <div>
                                <img [src]="provider.imageUrl" class="img-responsive cursor-pointer" alt="No Image" style="margin:auto;" (click)="navigateToEmployee(provider.id);"/>
                            </div>
                            <br/>
                            <button pButton type="button" icon="fa fa-address-card-o" iconPos="right" label="Read Bio... " (click)="navigateToEmployee(provider.id);" class="ui-button-info"></button>
                        </p-fieldset>
                    </div>                            
                </div>
                <br/>
                <br/>
                <br/>
                <help-contact></help-contact>
                <br/>
                <br/>
                <br/>
                <div class="row">
                    
                </div>              
            </div>
        </div>
    </div>