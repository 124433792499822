import { Component, OnInit } from '@angular/core';
import { IAboutUsDTO } from '../../dto/about.us.dto';
import { IProvidersDTO } from '../../dto/providers.dto';
import { IBioDataViewModel } from '../../viewmodels/biodata.viewmodel'; 
import { IEmployeeInfoSallowDTO } from '../../dto/employee.info.sallow.dto';
import {EmployeeInfoService} from "../../services/employee.info.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
       providersInfo : IEmployeeInfoSallowDTO[] = [];
      constructor(private _employeeService:EmployeeInfoService, 
                  private route: ActivatedRoute,
                  private router: Router) {

      }
      
      ngOnInit():void {        
           this._employeeService.getAllEmployeesSallow().subscribe(
            (data:IEmployeeInfoSallowDTO[])=>{
              this.providersInfo = data
            },
            error => {
              console.error(error);
            }
          )
      }

      //In Component Properties
      displayBioData: boolean = false;
      bgImageUrl:string ='assets/images/backgrounds/bgimg1.jpg';
      title:string = 'Home';
      bioDataFull:IBioDataViewModel = {
        fullName:'',
        imageUrl:'',
        bioData:''
      };
      
      ourSpecilization ={
        heading:"We specialize in :",
        messages:[
          "Treatment of  Acute Kidney Failure.",
          "Treatment of Chronic Kidney Disease.",
          "Incenter and Home Dialysis services for End Stage Renal Disease.",
          "Management of High Blood Pressure.",
          "Evaluation and treatment of blood and protein in the urine.",
          "Treatment of anemia related to Kidney Disease.",
          "Management of Fluid and Electrolytes.",
          "Treatment of severe edema associated with congestive heart failure.",
          "Bone Disease related to kidney disease.",
          "Evaluation and treatment of kidney stones.",
          "Treatment of polycystic kidney disease.",
          "Post renal transplant care."
        ]
      };
      aboutUs:IAboutUsDTO = {
        heading :`Our mission is to provide quality care to our patients.
        We put our patients' health first.`,
        message:`Cenla Kidney Specialists LLC is established with a commitment 
        to providing exceptional renal care to our patients with kidney diseases 
        and hypertension. Our physicians are board certified nephrologists and are
         experts in their field. Our team are experienced, knowledgeable and skilled, 
         who strive to know every patient and not just their illness. `
    };
     navigateToEmployee(id:number) {
      this.router.navigate([`aboutus`,id]);    
    }
}
