<div>

    <div class="panel panel-primary"> 
        <div class="panel-body">
                <section class="header" style="background-image:url(../assets/images/backgrounds/referrals.jpg)">
                    <div class="content">
                        <h3>{{referrals.heading}}</h3>
                        <h1>
                                {{referrals.message}}
                        </h1>                                                 
                    </div>    
                </section>
        </div>     
        <div class="panel-body">
            <h2 class="col-sm-offset-0 col-md-offset-1 col-md-10 text-center">
               To refer a patient call us at {{referrals.contact}}.
            </h2>
            <div class="col-sm-offset-0 col-md-offset-1 col-md-10  text-center font-size-17">
                <p> 
                    Use the following forms.
                </p>
                <p> 
                    Send us an inbound Continuity of Care Document (CCD) via fax at (318) 704-0002.  
                </p>
                <p>
                        If you have any questions please email us at <a href="mailto:cenlakidneyspecialists@gmail.com">cenlakidneyspecialists@gmail.com</a>.
                </p>          
            </div>
        </div>
    
        <div class="panel-body">
                <div class="container-fluid bg-3 text-center">
                        <div class="row">
                            <div class="col">
                                   <i class="fa fa-file-pdf-o fa-5x cursor-pointer" aria-hidden="true" (click)="downloadForm(referralFormInfo.path,referralFormInfo.formDownloadName)"></i>
                            </div>
                            <div class="col">
                                   Download {{referralFormInfo.formDisplayName}}
                            </div>
                        </div>
                  </div>
        </div>
        
        <hr/>   
    </div>
</div>