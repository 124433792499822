

<div class="jumbotron">
        <h2 class="text-center text-primary">Our Medical Team</h2>
        <p class="text-center">
               Learn in detail about our medical team.          
        </p>       
</div>

<div class="panel panel-primary">  
    <div *ngIf="employeeFound ===true  && loadingEmployee === false; then contentOne else contentTwo"></div> 
    <ng-template #contentOne>
            <div class="panel-body">
                    <div class="row">  
                        <div class="col-md-5" style="margin-top: 5px;"> 
                            <div class="col">
                                <h2 style="margin-left:5px;">{{employee.fullName}}</h2>
                                <div class="thumbnail" style="width:100%;">
                                        <img [src]="employee.imageUrl" class="img-responsive" alt="No Image" style="margin:auto;"/>
                                </div> 
                            </div>                                       
                        </div>
                        <div class="col-md-7" style="margin-top: 10px;">                                 
                            <div class="media" *ngIf="employee.educations !== null && employee.educations !== undefined && employee.educations.length > 0">
                                <div class="media-body">
                                    <h4 class="media-heading  text-primary">Education</h4>
                                    <div *ngFor="let education of employee.educations;">
                                        {{education}}
                                    </div>
                                </div>
                            </div>
                                                         
                                <div class="media" *ngIf="employee.awards !== null && employee.awards !== undefined && employee.awards.length > 0">
                                    <div class="media-body">
                                        <h4 class="media-heading  text-primary">Awards</h4>
                                        <div *ngFor="let award of employee.awards;">
                                            {{award}}
                                        </div>
                                    </div>
                                </div>
                            <div class="media margin-top-10" *ngIf=" employee.certifications !== null && employee.certifications !== undefined && employee.certifications.length > 0">
                                    <div class="media-body">
                                        <h4 class="media-heading  text-primary">Board Certifications</h4>
                                        <div *ngFor="let certification of employee.certifications;">
                                            {{certification}}
                                        </div>
                                    </div>
                            </div>
                            <div class="media margin-top-10" *ngIf=" employee.associations !== null && employee.associations !== undefined && employee.associations.length > 0">
                                    <div class="media-body">
                                        <h4 class="media-heading  text-primary">Associations</h4>
                                        <div *ngFor="let association of employee.associations;">
                                            {{association}}
                                        </div>
                                    </div>
                            </div>
                            <div class="media margin-top-10" *ngIf="employee.publications !== null && employee.publications !== undefined && employee.publications.length >0">
                                <div class="media-body">
                                    <h4 class="media-heading  text-primary">Publications</h4>
                                    <div *ngFor="let publication of employee.publications;">
                                        {{publication.information}} <span  *ngIf="publication.pmid !==''">PMID: <i class="cursor-pointer" (click)="navigateToPubMed(publication.pmid)"> {{publication.pmid}}</i></span>
                                    </div>
                                </div>
                        </div>
                        <div class="media margin-top-10" *ngIf="employee.abstracts !== null && employee.abstracts !== undefined && employee.abstracts.length >0">
                                <div class="media-body">
                                    <h4 class="media-heading  text-primary">Abstracts</h4>
                                    <div *ngFor="let abstract of employee.abstracts;">
                                        {{abstract}}
                                    </div>
                                </div>
                        </div>
                        <div class="media margin-top-10" *ngIf="employee.presentations !== null && employee.presentations !== undefined && employee.presentations.length>0">
                                <div class="media-body">
                                    <h4 class="media-heading  text-primary">Presentations</h4>
                                    <div *ngFor="let presentation of employee.presentations;">
                                        {{presentation}}
                                    </div>
                                </div>
                        </div>
                            <div class="media margin-top-10" *ngIf="employee.otherInfo !== null && employee.otherInfo !== ''">
                                    <div class="media-body">
                                        <h4 class="media-heading  text-primary">Other Information</h4>
                                        <div>
                                            {{employee.otherInfo}}
                                        </div>
                                    </div>
                           </div>                          
                        </div>  
                    </div>     
            </div> 
    </ng-template>
    <ng-template #contentTwo>
        <div class="panel-body">
            <div class="row">  
                <div class="col-md-12" style="margin-top: 5px;">                                
                <div *ngIf="loadingEmployee === false; then noEmployee  else empLoading"></div> 
                    <ng-template #empLoading>
                            <p-progressSpinner></p-progressSpinner> Loading Employee.......
                    </ng-template> 
                    <ng-template #noEmployee>
                            <div class="alert alert-danger" role="alert">Employee Not Found</div>
                    </ng-template>          
                </div>                                                                                                   
            </div>     
        </div>            
    </ng-template>
    
    <div class="panel-body">
        <div class="col">
                <div class="col-md-12" style="margin-top: 5px;">                          
                        <div style="margin-bottom: 5px;" *ngFor="let empTypeGroup of providersInfo">
                                <div class="alert alert-info" role="alert" style="margin-bottom: 0px;">{{empTypeGroup.employeeType}}</div>                                  
                                <div class="list-group" >
                                        <div *ngFor="let emp of empTypeGroup.employeeNameIds">
                                            <div *ngIf="isDisabled(emp.id); then linkOne else linkTwo"></div>
                                            <ng-template #linkOne>
                                                    <span  class="list-group-item disabled">{{emp.fullName}}</span>
                                            </ng-template>
                                            <ng-template #linkTwo>
                                                    <a [routerLink]="['/aboutus',emp.id]" class="list-group-item cursor-pointer" >{{emp.fullName}}</a>
                                            </ng-template>
                                        </div>                                                                                   
                                </div>                                 
                        </div>
                        <div style="margin-bottom: 5px;">
                                <div class="alert alert-info" role="alert" style="margin-bottom: 0px;">About Us</div>
                                <div class="list-group">   
                                        <div (click)="navigateToAboutUs()" class="list-group-item" style="cursor: pointer;">Go to About Us main page</div>                                         
                                </div>
                        </div>                       
                </div>
        </div>       
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <help-contact></help-contact>
    <br/>
    <br/>
    <br/>
    <div class="row">
        
    </div>   
</div> 

             
      
      