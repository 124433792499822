import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from "@angular/common/http";
import { PrimeNgImportsModule } from './primeng.imports.module';
import {FormsModule} from "@angular/forms";
import { AppComponent } from './app.component';
import { ContactUsComponent } from './components/contact/contact-us.component';
import { HomeComponent } from "../app/components/home/home.component";
import { AboutUsComponent } from "../app/components/aboutus/about-us.component";
import { ReferralsComponent } from '../app/components/referrals/referrals.component';
import { PatientsComponent } from '../app/components/patients/patients.component';
import { EmployeeComponent } from '../app/components/employees/employee.component';
import { ResearchComponent } from './components/research/research.component';
import { ResuableTemplates } from "./template.imports";
import { CommonService } from "./app-common.service";
import {EmployeeInfoService} from "../app/services/employee.info.service";
import {ConfigService} from "../app/services/config.service";

@NgModule({
  declarations: [
    AppComponent,
    ContactUsComponent,
    HomeComponent,
    AboutUsComponent,
    ReferralsComponent,
    PatientsComponent,
    EmployeeComponent,
    ResearchComponent,
    ResuableTemplates
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PrimeNgImportsModule
  ],
  providers: [EmployeeInfoService, ConfigService],
  bootstrap: [AppComponent]
})
export class AppModule { }
