import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ContactUsComponent } from './components/contact/contact-us.component';
import { AboutUsComponent } from './components/aboutus/about-us.component';
import { ReferralsComponent } from './components/referrals/referrals.component';
import { PatientsComponent } from './components/patients/patients.component';
import { EmployeeComponent } from './components/employees/employee.component';
import { ResearchComponent } from './components/research/research.component';
const routes: Routes = [
  {path:'home', component : HomeComponent},
  {path:'aboutus', component : AboutUsComponent},
  {path:'aboutus/:id', component : EmployeeComponent},
  {path:'patients', component : PatientsComponent},
  {path: 'contact', component: ContactUsComponent},
  {path: 'referrals', component: ReferralsComponent},
  {path: 'research', component: ResearchComponent},
  {path:'',  redirectTo:'/home', pathMatch:'full'},
  {path:'**',  redirectTo:'/home', pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

