import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http"
import {IConfigDTO} from "../dto/config.message.dto";
import { Observable } from 'rxjs';


@Injectable()
export class ConfigService {
    configUrl ="assets/data/config/config.json";
    constructor(private _http:HttpClient){

    }
    getConfig():Observable<IConfigDTO> {
        return this._http.get<IConfigDTO>(this.configUrl);
    }
    
  
}