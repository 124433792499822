import { Component, OnInit } from '@angular/core';
import { IEmployeeInfoDTO, IPublications} from '../../dto/employee.info.dto';
import {EmployeeType} from "../../dto/employee.type.dto";
import {EmployeeInfoService} from "../../services/employee.info.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {IEmployeeNameId} from "../../dto/employee.name.id.dto";
import {IEmployeeGropTypeNameIdDTO} from "../../dto/employee.groupType.name.id";
import {IEmployeeInfoSallowDTO} from "../../dto/employee.info.sallow.dto";
import {IDDLKeyValue} from "../../viewmodels/dropdown.viewmodel";
import {CommonFunctions} from "../../sharedFunctions/commom.functons"



@Component({
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {
    
      employeesSallow:IEmployeeInfoSallowDTO[] =[];
      providersInfo : IEmployeeGropTypeNameIdDTO[] = [];
      employee:IEmployeeInfoDTO =null;
      loadingEmployee:boolean =true;
      employeeFound :boolean= false;
      id:number=0;
      constructor(private _employeeService:EmployeeInfoService,
                    private route: ActivatedRoute,
                    private router: Router){

      }
      ngOnInit(){
         let inputID = this.route.snapshot.params["id"];        
          this._employeeService.getSingleEmployee(inputID).subscribe(
            (data:IEmployeeInfoDTO)=>{        
              this.employee =data !== null? {...data}:null; 
              if(data !== null){
                this.employeeFound =true;
              }
              else{
               this.employeeFound =false;
              }          
              this.loadingEmployee = false;
            },
            error => {
              console.error(error);
            }
          )
          this._employeeService.getAllEmployeesSallow().subscribe(
            (data:IEmployeeInfoSallowDTO[])=>{
              this.employeesSallow = [...data];
              this.providersInfo = this.getEmployeeSallowByType();
              this.loadingEmployee = false;
            },
            error => {
              console.error(error);
            }
          )
          this.id=inputID; 
          this.route.params.subscribe((x)=>{
            let inputID = this.route.snapshot.params["id"];
            this._employeeService.getSingleEmployee(inputID).subscribe(
              (data:IEmployeeInfoDTO)=>{
                this.employee = {...data}
                if(this.employee !== null && 
                   this.employee !== undefined && 
                   !CommonFunctions.isObjectEmpty(this.employee)){
                  this.employeeFound=true;
                }
                else{
                  this.employeeFound=false;
                }
                this.loadingEmployee = false;
              },
              error => {
                console.error(error);
              }
            )
            this.id=inputID;
            CommonFunctions.scrolltoTop();
          });     
      }

    navigateToEmployee(id:number) {
      this.router.navigate([`aboutus`,id]);    
    }

    navigateToAboutUs(){
      this.router.navigate([`aboutus`]);    
    }

    isDisabled(empId):boolean{
        if(isNaN(this.id)){
            return false;
        }
      return Number(empId) === Number(this.id);
    }
     
    getEmployeeSallowByType():IEmployeeGropTypeNameIdDTO[] {
      let empTypeNameIds= this.employeesSallow.map(x=>({ type: x.type,
                                                    id:x.id,
                                                    fullName :x.fullName}));
                                                    
        let beforeReturn = CommonFunctions.groupBy(empTypeNameIds,(x)=>x.type);
        let returnValue:IEmployeeGropTypeNameIdDTO[] =[];
      for(var empGroup in beforeReturn){  
          returnValue.push({employeeType:CommonFunctions.getEmployeeType(empGroup.toString()),
            employeeNameIds:beforeReturn[empGroup]});
        }
        return returnValue;
    }
    
   
    
    navigateToPubMed(pmid:string):void{
      window.open(`https://www.ncbi.nlm.nih.gov/pubmed/${pmid}`,"_blank")
    }
}