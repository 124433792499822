<div>
    <div class="panel panel-primary">
    <div class="panel-body">
            <section class="header" style="background-image:url(../assets/images/backgrounds/research.jpg)">
                <div class="content">
                    <h2 class="research-bold">{{researchPage.header}}</h2>
                    <h1 class="research-bold">
                       {{researchPage.message}}
                    </h1>
                                                                 
                </div>    
            </section>
    </div>       
        <div class="panel-body">
            <p class="font-size-17">
                {{researchInfo.messageHeader}}
            </p>
            <p class="font-size-17">
                {{researchInfo.messageBody}}
            </p>
            <ul class="font-size-17">
                <li *ngFor="let clinicalTrial of researchInfo.clinicalTrials">{{clinicalTrial}}</li>
            </ul>
            <p class="font-size-17 text-center footer-research" >
                    {{researchInfo.messageFooter}}
            </p>
        </div>        
    </div>
</div>